export const langSR = {
  navPocetna: "Početna",
  navOnama: "O nama",
  navProizvodi: "Proizvodi",
  nasiProizvodi: "Naši proizvodi",
  navKontakt: "Kontakt",
  podnaslov: "Izrada metalnih proizvoda.",
  email: "metaluniverstojanovic@gmail.com",
  viber: "Potražite nas na Viber-u",
  fejsbuk: "Potražite nas na Facebook-u",
  instagram: "Pratite nas na Instagram-u",
  tekstOnama:
    "Dugogodišnjim iskustvom, uspešnim radom i kvalitetom naših proizvoda možemo se pohvaliti velikim brojem zadovoljnih mušterija. Trudimo se da na svakom proizvodu pokažemo vrhunski kvalitet i izađemo u susret svim mogućim zahtevima klijenta. Nudimo profesionalnost u izradi svih naših proizvoda i u odnosu sa klijentima. Takođe se ističemo  konkurentnim cenama.",
  oNama: "U ponudi su više vrsta proizvoda od metala:",
  li_1: "Projektovanje i izrada kapija i ograda",
  li_2: "Izrada garažnih vrata",
  li_3: "Metalnih konstrukcija i kontejnera",
  li_4: "Gelendera terasa i stepeništa",
  li_5: "Izrada navoza i rampi",
  li_6: "Zaštitnih rešetki",
  li_7: "Nadstrešnica za garaže i letnje bašte",
  li_8: "Unutrašnjih i spoljašnjih stepeništa",
  li_9: "Visećih i kliznih vrata",
  li_10: "Metalne galanterije",
  li_11: "Dečijeg programa",
  li_12: "Bokseva za pse",
  proizvodiPoZelji:
    "Pored pomenutih vršimo izradu i drugih proizvoda po želji kupca!",
  kontaktirajteNas: "Kontaktirajte nas",
  kategorije: "Kategorije naših proizvoda",
  ogradeKapije: "Kapije",
  garaznaVrata: "Garažna vrata",
  stepenista: "Stepeništa",
  nadstresnice: "Nadstrešnice",
  gelenderi: "Gelenderi",
  hale: "Hale",
  zastitneResetke: "Zaštitne rešetke",
  visecaKliznaVrata: "Viseća i klizna vrata",
  galanterija: "Metalna galanterija",
  decijiProgram: "Dečiji program",
  palete: "Palete za transport",
  navoziRampe: "Navozi i rampe",
  sahte: "Metalne šahte",
  boksevi: "Boksevi za pse",
  odgovoriti:
    "Rado ćemo odgovoriti na svaki Vaš upit. Kontaktirajte nas putem telefona ili bilo kojim od ponuđenih načina.",
  _adresa: "Adresa: Radomira Jankovića 35/1, Radinac-Smederevo",
  _mob: "Mob: (+381) 64/24-87-845",
  _tel: "Tel: (+381) 26/701-923",
  _email: "E-mail: metaluniverstojanovic@gmail.com",
  unikat: "Želite li unikatni proizvod?",
  _kontakt:
    "Kontaktirajte nas i mi ćemo pokušati da odgovorimo na vaše zahteve.",
  vaseIme: "Vaše ime",
  vasEmail: "Vaš e-mail",
  vasaPoruka: "Vaša poruka",
  posaljite: "Pošaljite",
  poslato: "Poruka je uspešno poslata!",
};

export const langEN = {
  navPocetna: "Home",
  navOnama: "About",
  navProizvodi: "Products",
  nasiProizvodi: "Our products",
  navKontakt: "Contact",
  podnaslov: "Production of metal products.",
  email: "metaluniverstojanovic@gmail.com",
  viber: "Look for us on Viber",
  fejsbuk: "Look for us on Facebook",
  instagram: "Follow us on Instagram",
  tekstOnama:
    "We can boast of a large number of satisfied customers with many years of experience, successful work and the quality of our products. We try to show top quality on every product and meet all possible customer requirements. We offer professionalism in the manufacture of all our products and in relation to customers. We also stand out with competitive prices.",
  oNama: " We offer several types of metal products:",
  li_1: "Design and construction of gates and fences",
  li_2: "Making garage doors",
  li_3: "Metal structures and containers",
  li_4: "Handrails terrace and stairs",
  li_5: "Construction of slipways and ramps",
  li_6: "Protective grilles",
  li_7: "Canopy for garages and summer gardens",
  li_8: "Internal and external stairs",
  li_9: "Hanging and sliding doors",
  li_10: "Metal furniture",
  li_11: "Children's program",
  li_12: "Boxes for dogs",
  proizvodiPoZelji:
    "In addition to the above, we also make other products according to the customer's wishes!",
  kontaktirajteNas: "Contact us",
  kategorije: "Categories of our products",
  ogradeKapije: "Gates",
  garaznaVrata: "Garage Door",
  stepenista: "Stairs",
  nadstresnice: "Canopies",
  gelenderi: "Handrails",
  hale: "Hale",
  zastitneResetke: "Protective grilles",
  visecaKliznaVrata: "Hanging and sliding doors",
  galanterija: "Metal furniture",
  decijiProgram: "Children's program",
  palete: "Pallets for transport",
  navoziRampe: "Slipways and ramps",
  sahte: "Metal lids",
  boksevi: "Boxes for dogs",
  odgovoriti:
    "We will be happy to answer any of your questions. Contact us by phone or any of the methods offered.",
  _adresa: "Address: Radomira Jankovica 35/1, Radinac-Smederevo",
  _mob: "Mob: (+381) 64/24-87-845",
  _tel: "Phone: (+381) 26/701-923",
  _email: "E-mail: metaluniverstojanovic@gmail.com",
  unikat: "Do you want a unique product?",
  _kontakt: "Contact us and we will do our best to answer all your requests.",
  vaseIme: "Your name",
  vasEmail: "Your email",
  vasaPoruka: "Your message",
  posaljite: "Send",
  poslato: "Message sent successfully!",
};

export const langFR = {
  navPocetna: "Accueil",
  navOnama: "À propos",
  navProizvodi: "Produits",
  nasiProizvodi: "Nos produits",
  navKontakt: "Contact",
  podnaslov: "Production de produits métalliques.",
  email: "metaluniverstojanovic@gmail.com",
  viber: "Retrouvez-nous sur Viber",
  fejsbuk: "Retrouvez-nous sur Facebook",
  instagram: "Suis nous sur Instagram",
  tekstOnama:
    "Nous pouvons nous vanter d'un grand nombre de clients satisfaits avec de nombreuses années d'expérience, un travail réussi et la qualité de nos produits. Nous nous efforçons de montrer la meilleure qualité sur chaque produit et de répondre à toutes les exigences possibles des clients. Nous offrons du professionnalisme dans la fabrication de tous nos produits et en relation avec les clients. Nous nous démarquons également par des prix compétitifs.",
  oNama: "Nous proposons plusieurs types de produits:",
  li_1: "Conception et construction de portails et clôtures",
  li_2: "Fabrication de portes de garage",
  li_3: "Structures et conteneurs métalliques",
  li_4: "Main courante terrasse et escaliers",
  li_5: "Construction de cales et de rampes",
  li_6: "Grilles de protection",
  li_7: "Auvent pour garages et jardins d'été",
  li_8: "Escaliers intérieurs et extérieurs",
  li_9: "Portes suspendues et coulissantes",
  li_10: "Meubles en métal",
  li_11: "Programme pour enfants",
  li_12: "Cage pour chien",
  proizvodiPoZelji:
    "En plus de ce qui précède, nous fabriquons également d'autres produits selon les souhaits du client!",
  kontaktirajteNas: "Nous contacter",
  kategorije: "Catégories de nos produits",
  ogradeKapije: "Clôtures",
  garaznaVrata: "Porte de garage",
  stepenista: "Escaliers",
  nadstresnice: "Auvents",
  gelenderi: "Mains courantes",
  hale: "Constructions",
  zastitneResetke: "Grilles de protection",
  visecaKliznaVrata: "Portes suspendues et coulissantes",
  galanterija: "Meubles en métal",
  decijiProgram: "Programme pour enfants",
  palete: "Palettes pour le transport",
  navoziRampe: "Mouillages et rampes",
  sahte: "Couvercles métalliques",
  boksevi: "Cage pour chien",
  odgovoriti:
    "Nous serons ravis de répondre à toutes vos questions. Contactez-nous par téléphone ou par l'une des méthodes proposées.",
  _adresa: "Adresse: Radomira Jankovica 35/1, Radinac-Smederevo",
  _mob: "Mob: (+381) 64/24-87-845",
  _tel: "Télé: (+381) 26/701-923",
  _email: "E-mail: metaluniverstojanovic@gmail.com",
  unikat: "Voulez-vous un produit unique?",
  _kontakt: "Contactez-nous et nous essaierons de répondre à vos demandes.",
  vaseIme: "Votre nom",
  vasEmail: "Votre e-mail",
  vasaPoruka: "Votre message",
  posaljite: "Envoyer",
  poslato: "Message envoyé avec succès!",
};
